import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { PolandEodBondType } from '../../../api-client/generated'

interface Props {
  isModalShown: boolean
  onAdd: (value: any) => void
  onClose: () => void
}

const AddBondTypeModal = (props: Props) => {
  const [year, setYear] = useState<number>(new Date().getFullYear())
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1)
  const [firstYearInterestRate, setFirstYearInterestRate] = useState<number>(0)
  const [otherYearMargin, setOtherYearMargin] = useState<number>(0)

  const customStyles = {
    margin: '1rem'
  }

  const addBondType = () => {
    const newBondType: PolandEodBondType = {
      code: 'EOD',
      year,
      month,
      firstYearInterestRate,
      otherYearMargin
    }

    props.onAdd(newBondType)
  }

  return (
    <Modal
      show={props.isModalShown}
      style={customStyles}
      size={'lg'}
    >
      <Modal.Header>
        <h2>Add Bond Type</h2>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>Type</label>
          <input disabled={true} value={'EOD'} />
        </div>
        <div>
          <label>Year</label>
          <input type='number' defaultValue={year} onChange={(p) => setYear(Number(p.target.value)) }/>
        </div>
        <div>
          <label>Month</label>
          <input type='number' defaultValue={month} onChange={(p) => setMonth(Number(p.target.value))}/>
        </div>
        <div>
          <label>First Year Interest Rate [%]</label>
          <input type='number' defaultValue={firstYearInterestRate} onChange={(p) => setFirstYearInterestRate(Number(p.target.value))}/>
        </div>
        <div>
          <label>Other Year Margin [%]</label>
          <input type='number' defaultValue={otherYearMargin} onChange={(p) => setOtherYearMargin(Number(p.target.value))}/>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onClose()}>Cancel</Button>
        <Button onClick={addBondType}>Add</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddBondTypeModal
