/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddBondTypeQueryRequest,
    AddBondTypeQueryRequestFromJSON,
    AddBondTypeQueryRequestToJSON,
    CommandResult,
    CommandResultFromJSON,
    CommandResultToJSON,
    GetAllBondsQueryResponse,
    GetAllBondsQueryResponseFromJSON,
    GetAllBondsQueryResponseToJSON,
    GetAllBondsTypesQueryResponse,
    GetAllBondsTypesQueryResponseFromJSON,
    GetAllBondsTypesQueryResponseToJSON,
} from '../models';

export interface AddBondTypePostRequest {
    addBondTypeQueryRequest?: AddBondTypeQueryRequest;
}

/**
 * 
 */
export class BondApi extends runtime.BaseAPI {

    /**
     */
    async addBondTypePostRaw(requestParameters: AddBondTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommandResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/add-bond-type`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddBondTypeQueryRequestToJSON(requestParameters.addBondTypeQueryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultFromJSON(jsonValue));
    }

    /**
     */
    async addBondTypePost(requestParameters: AddBondTypePostRequest, initOverrides?: RequestInit): Promise<CommandResult> {
        const response = await this.addBondTypePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async allBondsTypesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAllBondsTypesQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/all-bonds-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllBondsTypesQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async allBondsTypesGet(initOverrides?: RequestInit): Promise<GetAllBondsTypesQueryResponse> {
        const response = await this.allBondsTypesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async rootGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAllBondsQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllBondsQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async rootGet(initOverrides?: RequestInit): Promise<GetAllBondsQueryResponse> {
        const response = await this.rootGetRaw(initOverrides);
        return await response.value();
    }

}
