/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BondTableMonthCell
 */
export interface BondTableMonthCell {
    /**
     * 
     * @type {number}
     * @memberof BondTableMonthCell
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof BondTableMonthCell
     */
    gainAccumulated?: number;
    /**
     * 
     * @type {number}
     * @memberof BondTableMonthCell
     */
    readonly gainMonthByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof BondTableMonthCell
     */
    readonly buyBondsCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BondTableMonthCell
     */
    readonly sellBondCount?: number | null;
}

export function BondTableMonthCellFromJSON(json: any): BondTableMonthCell {
    return BondTableMonthCellFromJSONTyped(json, false);
}

export function BondTableMonthCellFromJSONTyped(json: any, ignoreDiscriminator: boolean): BondTableMonthCell {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': !exists(json, 'month') ? undefined : json['month'],
        'gainAccumulated': !exists(json, 'gainAccumulated') ? undefined : json['gainAccumulated'],
        'gainMonthByMonth': !exists(json, 'gainMonthByMonth') ? undefined : json['gainMonthByMonth'],
        'buyBondsCount': !exists(json, 'buyBondsCount') ? undefined : json['buyBondsCount'],
        'sellBondCount': !exists(json, 'sellBondCount') ? undefined : json['sellBondCount'],
    };
}

export function BondTableMonthCellToJSON(value?: BondTableMonthCell | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'gainAccumulated': value.gainAccumulated,
    };
}

