import { AccountApi } from './generated/apis/AccountApi'
import { Configuration } from './generated/runtime'
import SessionStorageService from '../services/session-storage-service'
import configurationService from '../services/configuration-service'
import {
  AlertApi,
  AlertRuleApi,
  AssetsApi,
  AssistantApi,
  BondApi,
  CalculatorApi,
  CurrencyApi,
  HomeApi,
  ImportsApi,
  NobleMetalApi,
  StockApi,
  StockMarketIndexApi,
  UserAssetsApi
} from './generated'

const authToken = SessionStorageService.getAuthToken()

const apiConnectionConfig: Configuration = new Configuration({
  basePath: configurationService.getBaseUrl(),
  headers: {
    authorization: `Bearer ${authToken}`,
    'Access-Control-Allow-Origin': '*'
  }
})

export const alertApiFactory = () => new AlertApi(apiConnectionConfig)

export const alertRuleApiFactory = () => new AlertRuleApi(apiConnectionConfig)

export const assistantApiFactory = () => new AssistantApi(apiConnectionConfig)

export const accountApiFactory = () => new AccountApi(apiConnectionConfig)

export const stockMarketIndexApiFactory = () => new StockMarketIndexApi(apiConnectionConfig)

export const stockApiFactory = () => new StockApi(apiConnectionConfig)

export const importStatusesApiFactory = () => new ImportsApi(apiConnectionConfig)

export const currencyApiFactory = () => new CurrencyApi(apiConnectionConfig)

export const nobleMetalsApiFactory = () => new NobleMetalApi(apiConnectionConfig)

export const homeApiFactory = () => new HomeApi(apiConnectionConfig)

export const userAssetApiFactory = () => new UserAssetsApi(apiConnectionConfig)

export const assetsApiClient = () => new AssetsApi(apiConnectionConfig)

export const calculatorApiClient = () => new CalculatorApi(apiConnectionConfig)

export const bondsApiClient = () => new BondApi(apiConnectionConfig)
