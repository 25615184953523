/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BondTypeEntity,
    BondTypeEntityFromJSON,
    BondTypeEntityFromJSONTyped,
    BondTypeEntityToJSON,
} from './BondTypeEntity';
import {
    PolandEodBondTypeAllOf,
    PolandEodBondTypeAllOfFromJSON,
    PolandEodBondTypeAllOfFromJSONTyped,
    PolandEodBondTypeAllOfToJSON,
} from './PolandEodBondTypeAllOf';

/**
 * 
 * @export
 * @interface PolandEodBondType
 */
export interface PolandEodBondType {
    /**
     * 
     * @type {number}
     * @memberof PolandEodBondType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PolandEodBondType
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PolandEodBondType
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof PolandEodBondType
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof PolandEodBondType
     */
    firstYearInterestRate?: number;
    /**
     * 
     * @type {number}
     * @memberof PolandEodBondType
     */
    otherYearMargin?: number;
}

export function PolandEodBondTypeFromJSON(json: any): PolandEodBondType {
    return PolandEodBondTypeFromJSONTyped(json, false);
}

export function PolandEodBondTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolandEodBondType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'firstYearInterestRate': !exists(json, 'firstYearInterestRate') ? undefined : json['firstYearInterestRate'],
        'otherYearMargin': !exists(json, 'otherYearMargin') ? undefined : json['otherYearMargin'],
    };
}

export function PolandEodBondTypeToJSON(value?: PolandEodBondType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'year': value.year,
        'month': value.month,
        'firstYearInterestRate': value.firstYearInterestRate,
        'otherYearMargin': value.otherYearMargin,
    };
}

