import { Modal, Button } from 'react-bootstrap'
import ChooseAssetResource from '../AlertRules/ChooseAssetResource'
import { useState } from 'react'
import {
  ApiUserAssetsAddTransactionPostRequest,
  AssetTypeEnum,
  TransactionTypeEnum
} from '../../api-client/generated'
import { mapToBasketResource } from '../../extensions/Mappings/mapToBasketResource'

interface Props {
  isModalShown: boolean
  onAdd: (value: ApiUserAssetsAddTransactionPostRequest) => void
  onClose: () => void
}

const WalletAddTransactionModal = (props: Props) => {
  const [assetTypeSelected, setAssetTypeSelected] = useState<AssetTypeEnum | undefined>()
  const [resourceIdSelected, setResourceIdSelected] = useState<number>(0)
  const [transactionType, setTransactionType] = useState<string>()
  const [resourceAmount, setResourceAmount] = useState<number>(0)
  const [resourcePrice, setResourcePrice] = useState<number | undefined>()
  const [isResourcePriceDisabled, setIsResourcePriceDisabled] = useState<boolean>(false)

  const customStyles = {
    margin: '1rem'
  }

  const closeModal = () => {
    props.onClose()
  }

  const addTransaction = () => {
    const assetType = mapToBasketResource(assetTypeSelected)
    const param: ApiUserAssetsAddTransactionPostRequest = {
      transactionType:
        transactionType?.toUpperCase() === 'BUY'
          ? TransactionTypeEnum.Buy
          : TransactionTypeEnum.Sell,
      assetTypeSelected: assetType,
      resourceIdSelected,
      resourceAmount,
      resourcePrice
    }

    props.onAdd(param)

    props.onClose()
  }

  const onAssetTypeChanged = (selectedAssetTypeId: AssetTypeEnum) => {
    if (selectedAssetTypeId === AssetTypeEnum.Bond) {
      setIsResourcePriceDisabled(true)
      setResourcePrice(100)
    } else {
      setIsResourcePriceDisabled(false)
      setResourcePrice(undefined)
    }

    setAssetTypeSelected(selectedAssetTypeId)
  }

  return (
    <Modal
      show={props.isModalShown}
      onRequestClose={closeModal}
      style={customStyles}
      size={'lg'}
    >
      <Modal.Header>
        <h2>Add transaction</h2>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>
            Buy{' '}
            <input
              id="rbtnBuy"
              type="radio"
              name="transactionType"
              onChange={() => setTransactionType('buy')}
            />
          </label>
          <label>
            Sell{' '}
            <input
              id="rbtnSell"
              type="radio"
              name="transactionType"
              onChange={() => setTransactionType('sell')}
            />
          </label>
        </div>
        <hr />
        <ChooseAssetResource
          onAssetTypeChange={(p) => onAssetTypeChanged(p)}
          onResourceChange={(p) => setResourceIdSelected(p)}
          blockedAssetTypes={[AssetTypeEnum.MarketIndex]}
        />
        <div>
          <label>
            Amount
            <input
              type="number"
              onChange={(p) => setResourceAmount(Number(p.target.value))}
            />
          </label>
          <label>
            Price
            <input
              type="number"
              disabled={isResourcePriceDisabled}
              value={resourcePrice}
              onChange={(p) => setResourcePrice(Number(p.target.value))}
            />
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal}>Cancel</Button>
        <Button onClick={addTransaction}>Add</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default WalletAddTransactionModal
