import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { useCallback, useEffect, useRef, useState } from 'react'
import { bondsApiClient } from '../../../api-client/factory'
import { BondTableReview, BondTableYearRow } from '../../../api-client/generated'
import BondMonthCell from './BondMonthCell'
import BondYearReviewCell from './BondYearReviewCell'
import { Button } from 'react-bootstrap'
import './Bonds.scss'

const Bonds = () => {
  const gridRef = useRef<AgGridReact<any>>(null)

  const [rows, setRows] = useState<BondTableYearRow[]>([])
  const [review, setReview] = useState<BondTableReview>({})

  useEffect(() => {
    bondsApiClient().rootGet()
      .then(response => {
        // todo: zbieranie info o podsumowaniu
        setRows(response.items!)
        setReview(response.review!)
      })
  }, [])

  const getColumnDefinition = (name: string, monthNumber: number) => {
    return ({
      field: name,
      valueGetter: (row: any) => {
        return row.data.monthDetails[monthNumber - 1]
      },
      cellRenderer: BondMonthCell,
      cellRendererParams: {
        getYear: (params: any) => {
          return params.data.year
        },
        getDataValue: (params: any) => {
          return params.data.monthDetails[monthNumber - 1]
        }
      },
      autoHeight: true
    })
  }

  const onFirstDataRendered = useCallback(() => {
    gridRef?.current?.api?.sizeColumnsToFit()
  }, [])

  const columns: ColDef[] = [
    {
      field: 'year',
      headerName: 'Year',
      sortable: true
    },
    {
      field: 'yearReview',
      headerName: 'Year Review',
      valueGetter: (row: any) => {
        return row.data.yearReview
      },
      cellRenderer: BondYearReviewCell,
      cellRendererParams: {
        getDataValue: (params: any) => {
          return params.data.yearReview
        }
      }
    },
    getColumnDefinition('January', 1),
    getColumnDefinition('February', 2),
    getColumnDefinition('March', 3),
    getColumnDefinition('April', 4),
    getColumnDefinition('May', 5),
    getColumnDefinition('June', 6),
    getColumnDefinition('July', 7),
    getColumnDefinition('August', 8),
    getColumnDefinition('September', 9),
    getColumnDefinition('October', 10),
    getColumnDefinition('November', 11),
    getColumnDefinition('December', 12)
  ]

  return (
    <>
      <h1>Bonds</h1>
      <div className='headerContainer'>
        <div>
          <div>TOTAL BOND COUNT {review.totalCount}</div>
          <div>TOTAL BOND VALUE {!review.totalCount ? '-' : review.totalCount * 100} PLN</div>
        </div>
        <div>
          <Button disabled>Forecast</Button>
        </div>
      </div>
      <hr/>
      <div style={{ height: '75vh' }}>
        <div
          className="ag-theme-alpine"
          style={{ width: '100%', height: '100%' }}
        >
          <span>Gain is cumulatiove at the end of the month.</span>
          <AgGridReact
            ref={gridRef}
            rowData={rows}
            columnDefs={columns}
            onFirstDataRendered={onFirstDataRendered}
          />
        </div>
      </div>
    </>
  )
}

export default Bonds
