/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BasketResourceEnum {
    Stocks = 'Stocks',
    Currencies = 'Currencies',
    NobleMetals = 'NobleMetals',
    Bonds = 'Bonds'
}

export function BasketResourceEnumFromJSON(json: any): BasketResourceEnum {
    return BasketResourceEnumFromJSONTyped(json, false);
}

export function BasketResourceEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasketResourceEnum {
    return json as BasketResourceEnum;
}

export function BasketResourceEnumToJSON(value?: BasketResourceEnum | null): any {
    return value as any;
}

