/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BondTableMonthCell,
    BondTableMonthCellFromJSON,
    BondTableMonthCellFromJSONTyped,
    BondTableMonthCellToJSON,
} from './BondTableMonthCell';
import {
    BondTableYearRowReview,
    BondTableYearRowReviewFromJSON,
    BondTableYearRowReviewFromJSONTyped,
    BondTableYearRowReviewToJSON,
} from './BondTableYearRowReview';

/**
 * 
 * @export
 * @interface BondTableYearRow
 */
export interface BondTableYearRow {
    /**
     * 
     * @type {number}
     * @memberof BondTableYearRow
     */
    year?: number;
    /**
     * 
     * @type {BondTableYearRowReview}
     * @memberof BondTableYearRow
     */
    yearReview: BondTableYearRowReview;
    /**
     * 
     * @type {Array<BondTableMonthCell>}
     * @memberof BondTableYearRow
     */
    monthDetails?: Array<BondTableMonthCell> | null;
}

export function BondTableYearRowFromJSON(json: any): BondTableYearRow {
    return BondTableYearRowFromJSONTyped(json, false);
}

export function BondTableYearRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): BondTableYearRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': !exists(json, 'year') ? undefined : json['year'],
        'yearReview': BondTableYearRowReviewFromJSON(json['yearReview']),
        'monthDetails': !exists(json, 'monthDetails') ? undefined : (json['monthDetails'] === null ? null : (json['monthDetails'] as Array<any>).map(BondTableMonthCellFromJSON)),
    };
}

export function BondTableYearRowToJSON(value?: BondTableYearRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'yearReview': BondTableYearRowReviewToJSON(value.yearReview),
        'monthDetails': value.monthDetails === undefined ? undefined : (value.monthDetails === null ? null : (value.monthDetails as Array<any>).map(BondTableMonthCellToJSON)),
    };
}

