import { AssetTypeEnum, BasketResourceEnum } from '../../api-client/generated'

export const mapToBasketResource = (assetTypeSelected: AssetTypeEnum | undefined) => {
  const item = AssetTypeEnum[assetTypeSelected!]

  if (item === AssetTypeEnum.Bond) {
    return BasketResourceEnum.Bonds
  }

  if (item === AssetTypeEnum.Currency) {
    return BasketResourceEnum.Currencies
  }

  if (item === AssetTypeEnum.Stock) {
    return BasketResourceEnum.Stocks
  }

  if (item === AssetTypeEnum.NobleMetal) {
    return BasketResourceEnum.NobleMetals
  }

  return undefined
}
