import { useEffect, useState } from 'react'
import { bondsApiClient } from '../../../api-client/factory'
import { AddBondTypePostRequest, BondTypeEntity } from '../../../api-client/generated'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import AddBondTypeModal from './AddBondTypeModal'
import { showErrorToastr, showSuccessToastr } from '../../../services/toastr-notification-service'

const BondsTypesList = () => {
  const [rows, setRows] = useState<BondTypeEntity[]>([])
  const [isPopupShown, setIsPopupShown] = useState<boolean>(false)

  const columns: ColDef[] = [
    {
      field: 'code',
      sortable: true
    },
    {
      field: 'year',
      sortable: true
    },
    {
      field: 'month',
      sortable: true
    },
    {
      field: 'firstYearInterestRate',
      sortable: true
    },
    {
      field: 'otherYearMargin',
      sortable: true
    }
  ]

  useEffect(() => {
    bondsApiClient()
      .allBondsTypesGet()
      .then((response) => {
        setRows(response.items!)
      })
  }, [])

  const onAddButtonClick = () => {
    setIsPopupShown(true)
  }

  const onAdd = (newItem: any) => {
    const req: AddBondTypePostRequest = {
      addBondTypeQueryRequest: {
        bondType: newItem
      }
    }

    bondsApiClient().addBondTypePost(req)
      .then(() => {
        showSuccessToastr('Bond added')
      })
      .catch(() => {
        showErrorToastr('Error')
      })
      .finally(() => {
        setIsPopupShown(false)
      })
  }

  const onClose = () => {
    setIsPopupShown(false)
  }

  return (
    <>
      <h1>Bond types</h1>
      <button onClick={onAddButtonClick}>+ Add</button>
      <div style={{ height: '80vh' }}>
        <div
          className="ag-theme-alpine"
          style={{ width: '100%', height: '100%' }}
        >
          <AgGridReact
            rowData={rows}
            columnDefs={columns}
          />
        </div>
      </div>
      <AddBondTypeModal isModalShown={isPopupShown} onAdd={onAdd} onClose={onClose} />
    </>
  )
}

export default BondsTypesList
