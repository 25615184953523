/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BondTableReview,
    BondTableReviewFromJSON,
    BondTableReviewFromJSONTyped,
    BondTableReviewToJSON,
} from './BondTableReview';
import {
    BondTableYearRow,
    BondTableYearRowFromJSON,
    BondTableYearRowFromJSONTyped,
    BondTableYearRowToJSON,
} from './BondTableYearRow';

/**
 * 
 * @export
 * @interface GetAllBondsQueryResponse
 */
export interface GetAllBondsQueryResponse {
    /**
     * 
     * @type {BondTableReview}
     * @memberof GetAllBondsQueryResponse
     */
    review: BondTableReview;
    /**
     * 
     * @type {Array<BondTableYearRow>}
     * @memberof GetAllBondsQueryResponse
     */
    items?: Array<BondTableYearRow> | null;
}

export function GetAllBondsQueryResponseFromJSON(json: any): GetAllBondsQueryResponse {
    return GetAllBondsQueryResponseFromJSONTyped(json, false);
}

export function GetAllBondsQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllBondsQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'review': BondTableReviewFromJSON(json['review']),
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(BondTableYearRowFromJSON)),
    };
}

export function GetAllBondsQueryResponseToJSON(value?: GetAllBondsQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'review': BondTableReviewToJSON(value.review),
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(BondTableYearRowToJSON)),
    };
}

