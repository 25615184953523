/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetTypeEnum,
    AssetTypeEnumFromJSON,
    AssetTypeEnumFromJSONTyped,
    AssetTypeEnumToJSON,
} from './AssetTypeEnum';

/**
 * 
 * @export
 * @interface UserAssetsBaseEntity
 */
export interface UserAssetsBaseEntity {
    /**
     * 
     * @type {AssetTypeEnum}
     * @memberof UserAssetsBaseEntity
     */
    type?: AssetTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UserAssetsBaseEntity
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAssetsBaseEntity
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAssetsBaseEntity
     */
    buyPrice?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserAssetsBaseEntity
     */
    buyDate: Date;
    /**
     * 
     * @type {number}
     * @memberof UserAssetsBaseEntity
     */
    ownerId?: number;
}

export function UserAssetsBaseEntityFromJSON(json: any): UserAssetsBaseEntity {
    return UserAssetsBaseEntityFromJSONTyped(json, false);
}

export function UserAssetsBaseEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAssetsBaseEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : AssetTypeEnumFromJSON(json['type']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'buyPrice': !exists(json, 'buyPrice') ? undefined : json['buyPrice'],
        'buyDate': (new Date(json['buyDate'])),
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
    };
}

export function UserAssetsBaseEntityToJSON(value?: UserAssetsBaseEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': AssetTypeEnumToJSON(value.type),
        'id': value.id,
        'count': value.count,
        'buyPrice': value.buyPrice,
        'buyDate': (value.buyDate.toISOString()),
        'ownerId': value.ownerId,
    };
}

