/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import {
    StockQuote,
    StockQuoteFromJSON,
    StockQuoteFromJSONTyped,
    StockQuoteToJSON,
} from './StockQuote';
import {
    StockQuoteEntityAllOf,
    StockQuoteEntityAllOfFromJSON,
    StockQuoteEntityAllOfFromJSONTyped,
    StockQuoteEntityAllOfToJSON,
} from './StockQuoteEntityAllOf';

/**
 * 
 * @export
 * @interface StockQuoteEntity
 */
export interface StockQuoteEntity {
    /**
     * 
     * @type {Date}
     * @memberof StockQuoteEntity
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof StockQuoteEntity
     */
    opening?: number;
    /**
     * 
     * @type {number}
     * @memberof StockQuoteEntity
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof StockQuoteEntity
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof StockQuoteEntity
     */
    closing?: number;
    /**
     * 
     * @type {number}
     * @memberof StockQuoteEntity
     */
    volume?: number;
    /**
     * 
     * @type {number}
     * @memberof StockQuoteEntity
     */
    id?: number;
    /**
     * 
     * @type {Company}
     * @memberof StockQuoteEntity
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof StockQuoteEntity
     */
    companyId?: number;
}

export function StockQuoteEntityFromJSON(json: any): StockQuoteEntity {
    return StockQuoteEntityFromJSONTyped(json, false);
}

export function StockQuoteEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockQuoteEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'opening': !exists(json, 'opening') ? undefined : json['opening'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'closing': !exists(json, 'closing') ? undefined : json['closing'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
    };
}

export function StockQuoteEntityToJSON(value?: StockQuoteEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'opening': value.opening,
        'max': value.max,
        'min': value.min,
        'closing': value.closing,
        'volume': value.volume,
        'id': value.id,
        'company': CompanyToJSON(value.company),
        'companyId': value.companyId,
    };
}

