/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StockMarketIndexEntity,
    StockMarketIndexEntityFromJSON,
    StockMarketIndexEntityFromJSONTyped,
    StockMarketIndexEntityToJSON,
} from './StockMarketIndexEntity';
import {
    StockMarketIndexQuote,
    StockMarketIndexQuoteFromJSON,
    StockMarketIndexQuoteFromJSONTyped,
    StockMarketIndexQuoteToJSON,
} from './StockMarketIndexQuote';
import {
    StockMarketIndexQuoteEntityAllOf,
    StockMarketIndexQuoteEntityAllOfFromJSON,
    StockMarketIndexQuoteEntityAllOfFromJSONTyped,
    StockMarketIndexQuoteEntityAllOfToJSON,
} from './StockMarketIndexQuoteEntityAllOf';

/**
 * 
 * @export
 * @interface StockMarketIndexQuoteEntity
 */
export interface StockMarketIndexQuoteEntity {
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexQuoteEntity
     */
    open?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexQuoteEntity
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexQuoteEntity
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexQuoteEntity
     */
    close?: number;
    /**
     * 
     * @type {Date}
     * @memberof StockMarketIndexQuoteEntity
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexQuoteEntity
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexQuoteEntity
     */
    marketIndexId?: number;
    /**
     * 
     * @type {StockMarketIndexEntity}
     * @memberof StockMarketIndexQuoteEntity
     */
    marketIndex?: StockMarketIndexEntity;
}

export function StockMarketIndexQuoteEntityFromJSON(json: any): StockMarketIndexQuoteEntity {
    return StockMarketIndexQuoteEntityFromJSONTyped(json, false);
}

export function StockMarketIndexQuoteEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMarketIndexQuoteEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'open': !exists(json, 'open') ? undefined : json['open'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'close': !exists(json, 'close') ? undefined : json['close'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'marketIndexId': !exists(json, 'marketIndexId') ? undefined : json['marketIndexId'],
        'marketIndex': !exists(json, 'marketIndex') ? undefined : StockMarketIndexEntityFromJSON(json['marketIndex']),
    };
}

export function StockMarketIndexQuoteEntityToJSON(value?: StockMarketIndexQuoteEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'open': value.open,
        'max': value.max,
        'min': value.min,
        'close': value.close,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'id': value.id,
        'marketIndexId': value.marketIndexId,
        'marketIndex': StockMarketIndexEntityToJSON(value.marketIndex),
    };
}

