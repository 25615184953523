import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BaseListPage from '../../../Common/Base/BaseListPage/BaseListPage'
import { currencyApiFactory } from '../../../../api-client/factory'
import { ColDef } from 'ag-grid-community'
import { CurrencyQuoteItem } from '../../../../api-client/generated'
import { CurrenciesColumns } from './CurrenciesColumns'

const Currencies = () => {
  const navigate = useNavigate()

  const [rowData, setRowData] = useState<CurrencyQuoteItem[]>([])
  const [columns, setColumns] = useState<ColDef[]>([])
  const [statusDate, setStatusDate] = useState<Date | undefined>()

  useEffect(() => {
    currencyApiFactory()
      .apiCurrencyCurrentQuotesGet()
      .then((p) => {
        setRowData(p.items!)

        const cols = CurrenciesColumns(p.items![0], navigate)
        setColumns(cols)

        setStatusDate(p.statusDate)
      })
  }, [])

  return (
    <BaseListPage
      title="Currencies"
      statusDate={statusDate!}
      rows={rowData}
      columnsDef={columns}
    />
  )
}

export default Currencies
