import { ICellRendererParams } from 'ag-grid-community'
import { BondTableMonthCell } from '../../../api-client/generated'
import './BondMonthCell.scss'

interface Props extends ICellRendererParams
{
  getYear: (props: any) => number
  getDataValue: (props: any) => BondTableMonthCell
}

const BondMonthCell = (props: Props) => {
  const data = props.getDataValue(props)

  const isActive = () => {
    const today = new Date()
    return today.getMonth() + 1 === data.month && today.getFullYear() === props.getYear(props)
  }

  // todo: zmiana oprocentowania: dla obligacji jakoś wyświetlić (może inne kolor) + legenda
  // todo: pokazywanie inflacji?
  return !!data
    ? <div className={ isActive() ? 'currentPeriod' : '' }>
        {!!data.gainAccumulated && data.gainAccumulated !== 0 ? <div className='divShort'>ACC: {data.gainAccumulated}</div> : <></>}
        {!!data.gainMonthByMonth && data.gainMonthByMonth !== 0 ? <div className='divShort'>M/M: {data.gainMonthByMonth}</div> : <></>}
        {!!data.buyBondsCount || !!data.sellBondCount ? <hr/> : <></>}
        {!!data.buyBondsCount && data.buyBondsCount !== 0 ? <div className='divShort'>Buy: {data.buyBondsCount}</div> : <></>}
        {!!data.sellBondCount && data.sellBondCount !== 0 ? <div className='divShort'>Sell: {data.sellBondCount}</div> : <></>}
      </div>
    : ''
}

export default BondMonthCell
