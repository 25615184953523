import { ICellRendererParams } from 'ag-grid-community'

interface Props extends ICellRendererParams
{
  getDataValue: (props: any) => any
}

const BondYearReviewCell = (props: Props) => {
  const data = props.getDataValue(props)

  return <>
    {data.totalCount > 0 ? <div>Count: {data.totalCount}</div> : <></>}
  </>
}

export default BondYearReviewCell
