/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyBasicInfo,
    CompanyBasicInfoFromJSON,
    CompanyBasicInfoFromJSONTyped,
    CompanyBasicInfoToJSON,
} from './CompanyBasicInfo';
import {
    CompanyDividend,
    CompanyDividendFromJSON,
    CompanyDividendFromJSONTyped,
    CompanyDividendToJSON,
} from './CompanyDividend';
import {
    CompanyFinancialData,
    CompanyFinancialDataFromJSON,
    CompanyFinancialDataFromJSONTyped,
    CompanyFinancialDataToJSON,
} from './CompanyFinancialData';
import {
    CompanyIndicator,
    CompanyIndicatorFromJSON,
    CompanyIndicatorFromJSONTyped,
    CompanyIndicatorToJSON,
} from './CompanyIndicator';
import {
    CompanyRecommendationBasicInfo,
    CompanyRecommendationBasicInfoFromJSON,
    CompanyRecommendationBasicInfoFromJSONTyped,
    CompanyRecommendationBasicInfoToJSON,
} from './CompanyRecommendationBasicInfo';
import {
    QuotesStats,
    QuotesStatsFromJSON,
    QuotesStatsFromJSONTyped,
    QuotesStatsToJSON,
} from './QuotesStats';
import {
    StockQuote,
    StockQuoteFromJSON,
    StockQuoteFromJSONTyped,
    StockQuoteToJSON,
} from './StockQuote';
import {
    UserAssetsBaseEntity,
    UserAssetsBaseEntityFromJSON,
    UserAssetsBaseEntityFromJSONTyped,
    UserAssetsBaseEntityToJSON,
} from './UserAssetsBaseEntity';

/**
 * 
 * @export
 * @interface GetCompanyDetailsResponse
 */
export interface GetCompanyDetailsResponse {
    /**
     * 
     * @type {CompanyBasicInfo}
     * @memberof GetCompanyDetailsResponse
     */
    basicInfo?: CompanyBasicInfo;
    /**
     * 
     * @type {Array<StockQuote>}
     * @memberof GetCompanyDetailsResponse
     */
    quotes?: Array<StockQuote> | null;
    /**
     * 
     * @type {UserAssetsBaseEntity}
     * @memberof GetCompanyDetailsResponse
     */
    basket?: UserAssetsBaseEntity;
    /**
     * 
     * @type {CompanyIndicator}
     * @memberof GetCompanyDetailsResponse
     */
    indicators?: CompanyIndicator;
    /**
     * 
     * @type {CompanyDividend}
     * @memberof GetCompanyDetailsResponse
     */
    dividends?: CompanyDividend;
    /**
     * 
     * @type {CompanyRecommendationBasicInfo}
     * @memberof GetCompanyDetailsResponse
     */
    recommendations?: CompanyRecommendationBasicInfo;
    /**
     * 
     * @type {QuotesStats}
     * @memberof GetCompanyDetailsResponse
     */
    quotesStats?: QuotesStats;
    /**
     * 
     * @type {CompanyFinancialData}
     * @memberof GetCompanyDetailsResponse
     */
    financialData?: CompanyFinancialData;
}

export function GetCompanyDetailsResponseFromJSON(json: any): GetCompanyDetailsResponse {
    return GetCompanyDetailsResponseFromJSONTyped(json, false);
}

export function GetCompanyDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCompanyDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'basicInfo': !exists(json, 'basicInfo') ? undefined : CompanyBasicInfoFromJSON(json['basicInfo']),
        'quotes': !exists(json, 'quotes') ? undefined : (json['quotes'] === null ? null : (json['quotes'] as Array<any>).map(StockQuoteFromJSON)),
        'basket': !exists(json, 'basket') ? undefined : UserAssetsBaseEntityFromJSON(json['basket']),
        'indicators': !exists(json, 'indicators') ? undefined : CompanyIndicatorFromJSON(json['indicators']),
        'dividends': !exists(json, 'dividends') ? undefined : CompanyDividendFromJSON(json['dividends']),
        'recommendations': !exists(json, 'recommendations') ? undefined : CompanyRecommendationBasicInfoFromJSON(json['recommendations']),
        'quotesStats': !exists(json, 'quotesStats') ? undefined : QuotesStatsFromJSON(json['quotesStats']),
        'financialData': !exists(json, 'financialData') ? undefined : CompanyFinancialDataFromJSON(json['financialData']),
    };
}

export function GetCompanyDetailsResponseToJSON(value?: GetCompanyDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'basicInfo': CompanyBasicInfoToJSON(value.basicInfo),
        'quotes': value.quotes === undefined ? undefined : (value.quotes === null ? null : (value.quotes as Array<any>).map(StockQuoteToJSON)),
        'basket': UserAssetsBaseEntityToJSON(value.basket),
        'indicators': CompanyIndicatorToJSON(value.indicators),
        'dividends': CompanyDividendToJSON(value.dividends),
        'recommendations': CompanyRecommendationBasicInfoToJSON(value.recommendations),
        'quotesStats': QuotesStatsToJSON(value.quotesStats),
        'financialData': CompanyFinancialDataToJSON(value.financialData),
    };
}

